<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    :color="themeColor"
    dark
  >
    <v-app-bar-nav-icon
      @click.stop="toggleDrawer"
      class="ml-0"
    ></v-app-bar-nav-icon>

    <a href="/" style="margin-top: 10px">
      <!-- <img src="@/assets/logo.jpg" alt="logo" width="50" /> -->
    </a>

    <div class="flex-grow-1"></div>
    <!-- <v-btn class="hidden-sm-and-down" text to="/calendar">
      <v-icon>mdi-calendar </v-icon>
    </v-btn> -->

    <v-menu>
      <template v-slot:activator="{ on }">
        <v-btn tag="button" text v-on="on">
          สวัสดี {{ displayName }} | {{ roleDisplay }}
          <v-icon class="ml-2">mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <v-list class="mt-10">
        <v-list-item :to="'/profile'">
          <v-list-item-title>
            <v-icon class="mr-2">mdi-account</v-icon>Profile
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="'/login'">
          <v-list-item-title>
            <v-icon class="mr-2">mdi-logout</v-icon>ออกจากระบบ
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
// Utilities
import { mapMutations } from "vuex";
import { getToken } from "./../../js/authorization";

export default {
  data: () => ({
    drawer: null,
  }),

  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },

  methods: {
    ...mapMutations("app", ["setDrawer", "toggleDrawer"]),
    onClick() {
      this.setDrawer(!this.$store.state.app.drawer);
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },
  },
  computed: {
    title() {
      // return `${process.env.VUE_APP_NAME} [${process.env.VUE_APP_SERVER}]`;
      return `[${process.env.VUE_APP_SERVER}]`;
    },
    roleDisplay() {
      this.currentRole;

      if (this.currentRole) {
        let arr = this.currentRole.split(",");
        return arr[0];
      }

      return "";
    },
  },
};
</script>

<style>
/* Fix coming in v2.0.8 */
#core-app-bar {
  width: auto;
}

#core-app-bar a {
  text-decoration: none;
}
</style>
